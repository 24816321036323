import React from 'react'
import Page from '../components/page/Page'

const NotFound = () => {
    return (
        <Page id={'404Page'}>
            <div className={'flex w-page h-page-xl'}>
                <div className={' text-3xl m-auto'}>Not Found</div>
            </div>
        </Page>
    )
}

export default NotFound
